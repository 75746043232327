<template>
  <div>
    <el-container>
      <el-header>
        <Header class="animated fadeInDown" :is-home="true"></Header>
      </el-header>
      <el-main class="mainSection">
        <div class="pre-introduction">
          <Scrollama :offset="0.6" @step-enter="stepEnterHandler">
            <div class="container " data-step-no="0">
              <div v-if="searchFlag" class="animated fadeInUpBig">
                <img src="https://cos.mooc.huangzhaokun.top/moocImg/logo.png" alt="" style="width: 200px"
                >
                <h3>HUNAN AGRICULTURE UNIVERSITY</h3>
                <div class="search">
                  <el-row style="padding: 10px 0;">
                    <el-col :span="12">
                      <el-input
                        placeholder="请输入内容"
                        v-model="searchInput"
                        clearable>
                      </el-input>
                    </el-col>
                    <el-col :span="6" :offset="1">
                      <el-button @click="searchCourse" style="width: 130px" icon="el-icon-search" type="primary" round>
                        查找
                      </el-button>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </Scrollama>
          <div id="wCurtain" class="whiteCurtain animated"></div>
        </div>
        <div style="padding-top:80px" class="bg-light"
        >
          <div class="container">
            <h2 class="heading animate">POPULAR COURSES<span class="divider-left"></span></h2>
            <br>
            <Courses class="animate" data-delay="200"></Courses>
          </div>
        </div>
<!--        <SlickFullWidth :img-urls="imgUrls"></SlickFullWidth>-->
        <div style="padding-top:80px;" class="">
          <div class="container">
            <h2 class="heading animate">HEATED BLOGS<span class="divider-left"></span></h2>
            <br>
            <el-row :gutter="20">
              <el-col :md="24" :sm="24">
                <div class="blogs animate animated" data-delay="200">
                  <div id="hover1" class="blogCol" @mouseover="handleHover">
                    <div style="display: flex;justify-content: space-between;align-items: flex-end">
                      <h3 style="margin-bottom: 0">热门话题</h3>
                      <el-button class="blogBtn" v-if="btn1" style="height: 40px;margin-bottom: 0" round
                                 @click="goBlogs">查看更多
                      </el-button>
                    </div>
                    <el-divider></el-divider>
                    <div v-for="(hot,i) in hots" :key="i">
                      <p style="cursor: pointer" @click="goBlogDetails(hot.id)">
                        {{ i + 1 }}、
                        {{ hot.title }}
                        <small style="margin-left: 10px">
                          <b-icon icon="hand-thumbs-up"></b-icon>
                          33251</small>
                      </p>
                    </div>
                  </div>
                  <div id="hover2" class="blogCol flex15" @mouseover="handleHover2">
                    <div>
                      <h3>热门话题</h3>
                      <el-divider></el-divider>
                      <div v-for="(hot,i) in hots" :key="i">
                        <p style="cursor: pointer">
                          {{ i + 1 }}、
                          <a @click="goBlogDetails(hot.id)">{{ hot.title }}</a>
                          <small style="margin-left: 10px">
                            <b-icon icon="hand-thumbs-up"></b-icon>
                            33251</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="expertSection bg-light">
          <div class="container xqhzTea-list">
            <h2 class="heading animate">OUR TEACHERS<span class="divider-left"></span></h2>
            <br>
            <el-row :gutter="20">
              <el-col :md="12" :sm="24" :xl="12" class="animate animated" data-delay="200">
                <div>
                  <img
                    src="https://cos.mooc.huangzhaokun.top/moocImg/W020160629352592446326.jpg"
                    alt="" class="border-right">
                </div>
                <div class="xqhzTea-R shadow-sm animate">
                  <h6>聂笑一</h6>
                  <ul>
                    <li>湖南农业大学副教授</li>
                    <li>电子商务系副主任</li>
                    <li>互联网创新实验室负责人</li>
                  </ul>
                </div>
              </el-col>
              <el-col :md="12" :sm="24" :xl="12" class="animate" data-delay="400">
                <div>
                  <img src="https://cos.mooc.huangzhaokun.top/moocImg/hb.jpg" alt=""
                       class="border-right">
                </div>
                <div class="xqhzTea-R shadow-sm">
                  <h6>黄 博</h6>
                  <ul>
                    <li>系统架构设计师</li>
                    <li>Oracle OCP认证Java讲师</li>
                    <li>精通Java、Vue、AngularJs等主流技术</li>
                  </ul>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
      <Footer></Footer>
    </el-container>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { handleScroll } from '@/assets/api/animate'
import Courses from '@/components/Courses'
import Scrollama from 'vue-scrollama'
// import SlickFullWidth from '@/components/displays/SlickFullWidth'

export default {
  name: 'Home',
  components: {
    // SlickFullWidth,
    Courses,
    Footer,
    Header,
    Scrollama
    // Slick
  },
  data () {
    return {
      searchInput: '',
      headerFlag: false,
      searchFlag: false,
      btn1: false,
      imgUrls: [
        'https://cos.mooc.huangzhaokun.top/moocImg/teacherBg.png',
        'https://cos.mooc.huangzhaokun.top/moocImg/teacherBg.png',
        'https://cos.mooc.huangzhaokun.top/moocImg/teacherBg.png',
        'https://cos.mooc.huangzhaokun.top/moocImg/teacherBg.png'
      ],
      hots: [{
        title: 'java-日期',
        id: 1500011
      }, {
        title: 'java-异常',
        id: 1500013
      }, {
        title: 'java-枚举类',
        id: 1500014
      }, {
        title: 'java-泛型',
        id: 1500015
      }, {
        title: 'javaList',
        id: 1500016
      }]
    }
  },
  methods: {
    searchCourse () {
      this.$router.push({
        name: 'Courses',
        params: {
          searchInput: this.searchInput
        }
      })
    },
    goBlogs () {
      this.$router.push({ name: 'Blogs' })
    },
    stepEnterHandler ({
      element,
      index,
      direction
    }) {
      document.getElementById('wCurtain').classList.add('w-0')
      this.searchFlag = true
    },
    handleHover () {
      this.btn1 = true
      document.getElementById('hover1').classList.add('flex15')
      document.getElementById('hover2').classList.remove('flex15')
    },
    handleHover2 () {
      this.btn1 = false
      document.getElementById('hover2').classList.add('flex15')
      document.getElementById('hover1').classList.remove('flex15')
    },
    getElementToPageTop (el) {
      if (el.parentElement) {
        return this.getElementToPageTop(el.parentElement) + el.offsetTop
      }
      return el.offsetTop
    },
    goBlogDetails (blogId) {
      this.$router.push({
        name: 'BlogDetails',
        params: {
          blogId: blogId
        }
      })
    },
    handleAnimate () {
      const top = pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const vh = document.documentElement.clientHeight
      const dom = document.querySelectorAll('.animate');
      [].slice.call(dom).forEach(v => {
        v.style.opacity = 0
        if (top + 0.5 * vh > this.getElementToPageTop(v) / 3.09) {
          if (v.dataset.delay) {
            setTimeout(() => {
              v.style.opacity = 1
              v.classList.add('animated')
              if (v.dataset.ani) {
                v.classList.add(v.dataset.ani)
              } else {
                v.classList.add('fadeIn')
              }
            }, v.dataset.delay)
          } else {
            v.style.opacity = 1
            v.classList.add('animated')
            if (v.dataset.ani) {
              v.classList.add(v.dataset.ani)
            } else {
              v.classList.add('fadeIn')
            }
          }
        }
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.handleAnimate()
      addEventListener('scroll', handleScroll)
      addEventListener('scroll', this.handleAnimate)
    })
  },
  destroyed () {
    removeEventListener('scroll', handleScroll)
    removeEventListener('scroll', this.handleAnimate)
  }
}
</script>

<style scoped>
.whiteCurtain {
  width: 100%;
  height: 100vh;
  background: white;
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 0;
  transition: 1.5s ease-in-out;
}

.w-0 {
  width: 0 !important;
}

.pre-introduction {
  background: url(https://cos.mooc.huangzhaokun.top/moocImg/background2.png);
  background-repeat: no-repeat;
  background-position: 0 65%;
  background-size: 100%;
  height: 100vh;
  padding: 100px 0;
  box-sizing: border-box;
}

.flex15 {
  flex: 1.5 !important;
  background: #0052D9 !important;
  color: #ffffff;
}

#hover1 {
  margin-right: 32px;
}

.blogs {
  justify-content: space-between;
  margin: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-bottom: 180px;
}

.blogBtn {
  color: #ffffff;
  border: 2px solid #ffffff;
  background-color: transparent;
  transition: 0.35s;
}

.blogBtn:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.blogCol p {
  position: relative;
  z-index: 100;
  /*transition: 0.35s;*/
}

.blogCol p:hover {
  color: rgba(255, 255, 255, 0.6);
}

.blogCol {
  justify-content: space-between;
  height: 450px;
  flex: 1;
  background-color: rgba(255, 255, 255, 1);
  box-sizing: border-box;
  padding: 48px;
  padding-top: 44px;
  border-radius: 24px;
  transition: 0.35s;
  overflow: hidden;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 16%);
}

.mainSection {
  display: block;
  width: 100%;
  padding: 0;
  height: 100%;
}

.animate {
  opacity: 0;
}

.fadeInDown {
  animation: fadeInDown .5s ease-in;
}

.owlImg img {
  width: 100%;
}

a {
  text-decoration: none;
}

.expertSection {
  /*background: url(https://cos.mooc.huangzhaokun.top/moocImg/teacherBg.png);*/
  background-repeat: no-repeat;
  background-position: 0 80%;
  background-size: 100%;
  padding: 100px 0;
  box-sizing: border-box;
}

.xqhzTea-R {
  padding: 35px 15px 20px 33px;
  float: left;
  width: calc(100% - 266px);
  background-color: #ffffff;
  height: 355px;
  margin-bottom: 80px;
  overflow: hidden;
  box-sizing: border-box;
}

.xqhzTea-R h6 {
  font-size: 22px;
  color: #333333;
  padding-bottom: 25px;
  line-height: 1;
  border-bottom: 1px solid #b2bcca;
  margin-right: 18px;
}

.xqhzTea-R ul {
  margin-top: 20px;
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.xqhzTea-list {
  overflow: hidden;
}

.xqhzTea-list img {
  width: 266px;
  height: 355px;
  display: inline-block;
  float: left;
}

.xqhzTea-R li {
  list-style: none;
}

.heading .divider-left, .heading .divider-center {
  height: 4px;
  width: 70px;
  background: #4587d9;
  display: block;
}

.heading .divider-left {
  margin: 10px 0 -2px;
}

#hover1::after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: 37%;
  height: 100%;
  background-image: url(https://cos.mooc.huangzhaokun.top/moocImg/quarter.png);
  background-size: cover;
  transform: rotate(180deg);
  z-index: 10;
}

#hover2::after {
  position: absolute;
  bottom: 0;
  right: 0;
  content: '';
  width: 26.88%;
  height: 60%;
  background-image: url(https://cos.mooc.huangzhaokun.top/moocImg/quarter.png);
  background-size: cover;
}

.slickItem {
  width: 100px;
  height: 100px;
  background-color: #348AC7;
}
</style>
