<template>
  <!-- Courses -->
  <section id="course_all" class="padding-bottom">
    <div class="container">
      <el-row :gutter="25">
        <el-col :sm="12" :md="8" >
          <div class="course margin_top"
               @click="$router.push({name:'Course',params:{courseId:'300001'}})">
            <div class="image">
              <img src="https://cos.mooc.huangzhaokun.top/moocImg/courses4.jpg" alt="Course" class="border_radius">
            </div>
            <h3 class="bottom10">
              Adobe全家桶从入门到精通</h3>
          </div>
        </el-col>
        <el-col :sm="12" :md="8"  >
          <div class="course margin_top"
               @click="$router.push({name:'Course',params:{courseId:'300002'}})">
            <div class="image">
              <img src="https://cos.mooc.huangzhaokun.top/moocImg/courses6.jpg" alt="Course" class="border_radius">
            </div>
            <h3 class="bottom10">SpringBoot一站式教程</h3>
          </div>
        </el-col>
        <el-col :sm="12" :md="8" >
          <div class="course margin_top wow fadeIn" @click="$router.push({name:'Course',query:{courseId:'300003'}})">
            <div class="image">
              <img src="https://cos.mooc.huangzhaokun.top/moocImg/courses5.jpg" alt="Course" class="border_radius">
            </div>
            <h3 class="bottom10">HTML5+CSS3从入门到精通</h3>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Courses'
}
</script>

<style scoped>
h3{
  text-align: center;
}
.course{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding-bottom: 5px;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.35s;
}
.course:hover{
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.padding-bottom {
  padding-bottom: 90px;
}

.border_radius {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.image, .image img {
  position: relative;
  border-radius: 10px 10px 0 0;
}

.image img {
  -moz-transition: all .8s ease;
  -ms-transition: all .8s ease;
  -o-transition: all .8s ease;
  -webkit-transition: all .8s ease;
  transition: all .8s ease;
  width: 100%;
}

.image {
  overflow: hidden;
  height: 235px;
}

.image:hover img,
.course:hover .image img{
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
}
</style>
